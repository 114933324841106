import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
export default function Footer() {
    const { t, i18n } = useTranslation();

    i18n.on('languageChanged', (lng) => {
        document.documentElement.lang = lng;
        document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
    });


    return (
        <footer className="py-4 border-t-4 border-black">
            <div className="f-col container flex justify-between gap-[40px] pb-4">
                <div className="respon-100 w-[50%] flex flex-col gap-[20px]">
                    <div className="w-[80px]">
                        <img className="w-[80px]" src={require("../../Assest/logo/logo.png")} alt="" />
                    </div>
                    <p>
                        {t("about_footer")}
                    </p>
                    <div className="flex items-center gap-[10px]">
                        <Link to={"https://www.facebook.com/profile.php?id=61567136970290"} className="bg-[#FF723A] w-[35px] h-[35px] flex items-center justify-center rounded-full">
                            <img src={require("../../Assest/icons/facebook.svg").default} alt="" />
                        </Link>
                        <Link to={"https://www.instagram.com/smartitsm/"} className="bg-[#FF723A] w-[35px] h-[35px] flex items-center justify-center rounded-full">
                            <img src={require("../../Assest/icons/insta.svg").default} alt="" />
                        </Link>
                        <Link to={"https://wa.link/fcwpin"} className="bg-[#FF723A] w-[35px] h-[35px] flex items-center justify-center rounded-full">
                            <img src={require("../../Assest/icons/whatsapp.svg").default} alt="" />
                        </Link>
                        <Link to={""} className="bg-[#FF723A] w-[35px] h-[35px] flex items-center justify-center rounded-full">
                            <img src={require("../../Assest/icons/tiktok.svg").default} alt="" />
                        </Link>
                    </div>
                </div>
                <div className="f-col g-20 w-[50%] flex justify-around">
                    <div className="flex flex-col gap-[20px]">
                        <div className="text-[20px] font-bold capitalize">{t("important_links")}</div>
                        <ul className="flex flex-col gap-[8px]">
                            <li>
                                <Link className="capitalize" to="/home">{t("Home")}</Link>
                            </li>
                            <li>
                                <Link className="capitalize" to="/about">{t("About_us")}</Link>
                            </li>
                            <li>
                                <Link className="capitalize" to="/major">{t("University_Majors")}</Link>
                            </li>
                            <li>
                                <Link className="capitalize" to="/universities">{t("Universities")}</Link>
                            </li>
                            <li>
                                <Link className="capitalize" to="/courses">{t("Courses")}</Link>
                            </li>
                            <li>
                                <Link className="capitalize" to="/contact">{t("Contact_us")}</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="flex flex-col gap-[20px]">
                        <div className="text-[20px] font-bold capitalize">{t("contact_us")}</div>
                        <ul className="flex flex-col gap-[8px]">
                            <li>
                                <Link className="flex items-center gap-[5px]" to={""}>
                                    <img src={require("../../Assest/icons/location.svg").default} alt="" />
                                    <span>{t("location")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link className="flex items-center gap-[5px]" to={"tel:0792019411"}>
                                    <img src={require("../../Assest/icons/phone.svg").default} alt="" />
                                    <span>{t("phone")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link className="flex items-center gap-[5px]" to={"mailto:info@smart-itsm.site"}>
                                    <img src={require("../../Assest/icons/email.svg").default} alt="" />
                                    <span>{t("email")}</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="py-2.5 border-t border flex items-center justify-center capitalize gap-1.5">
                {t("developed_by")} <Link to={"https://zaidelayan.site/"}>Zaid Elayan</Link>
            </div>
        </footer>
    );
}