import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { universitiesData } from '../Data/universitiesData ';
import useScrollAnimation from '../useScrollAnimation';
export default function Universities() {
    const { t } = useTranslation();
    useScrollAnimation();

    const renderUniversity = (university) => (
        <div className='respon-100 flex flex-col gap-[10px] w-[300px]' key={university.name}>
            <Link to={university.url} className='respon-100 flex relative w-[300px] h-[200px] rounded-[8px]'>
                <img className='respon-100 absolute w-[300px] h-[200px] left-[0px] top-[0px] rounded-[8px]' src={university.image} alt={university.name} />
            </Link>
            <div className='font-bold capitalize'>{t(university.name)}</div>
        </div>
    );
    return (
        <>
            <section className="landing">
                <div className="container h-[100%] flex items-center">
                    <div className="flex flex-col gap-[10px]">
                        <div className="btmAni2000 text-[50px] font-bold capitalize text-[#FF723A]">{t("Universities")}</div>
                        <div className="btmAni25002 home-pages-title flex items-center gap-[5px] text-[20px] capitalize">
                            {t("Home")} - <div className="text-[#FF723A]">{t("Universities")}</div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='btmAni2000 container py-[40px] relative flex flex-col gap-[5px]'>
                    <div className='py-[10px]'>
                        <div className='text-[35px] font-bold capitalize pb-2.5'>{t("Public universities")}</div>
                        <div className='flex items-center justify-between flex-wrap gap-[20px]'>
                            {universitiesData.publicUniversities.map(renderUniversity)}
                        </div>
                    </div>
                    <div className='bg-[#eee] h-[1px] w-[100%]'></div>
                    <div className='py-[10px]'>
                        <div className='text-[35px] font-bold capitalize pb-2.5'>{t("Private universities")}</div>
                        <div className='flex items-center justify-between flex-wrap gap-[20px]'>
                            {universitiesData.privateUniversities.map(renderUniversity)}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}