import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from 'react-i18next';

export default function Header() {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isMenuActive, setIsMenuActive] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    i18n.on('languageChanged', (lng) => {
        document.documentElement.lang = lng;
        document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
    });

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleMenu = () => {
        setIsMenuActive(!isMenuActive);
        setIsOpen(false);
    };

    const location = useLocation();

    return (
        <header className="bg-white p-4 relative z-10" style={{ boxShadow: '0px 0px 24px #0000003d' }}>
            <div className="container flex items-center justify-between">
                <div className="w-[80px]">
                    <img className="w-[80px]" src={require("../../Assest/logo/logo.png")} alt="logo" />
                </div>
                <nav className={`d-none flex-col gap-4 md:flex md:flex-row md:gap-0`}>
                    <ul className="flex items-center gap-[10px]">
                        {/* Navigation Links */}
                        <li>
                            <Link
                                className={`font-bold capitalize transition duration-500 ${location.pathname === '/' ? 'text-[#ff723a] border-b-2 border-[#ff723a]' : 'text-black'}`}
                                to="/"
                            >
                                {t("Home")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={`font-bold capitalize transition duration-500 ${location.pathname === '/about' ? 'text-[#ff723a] border-b-2 border-[#ff723a]' : 'text-black'}`}
                                to="/about"
                            >
                                {t("About_us")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={`font-bold capitalize transition duration-500 ${location.pathname === '/major' ? 'text-[#ff723a] border-b-2 border-[#ff723a]' : 'text-black'}`}
                                to="/major"
                            >
                                {t("University_Majors")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={`font-bold capitalize transition duration-500 ${location.pathname === '/universities' ? 'text-[#ff723a] border-b-2 border-[#ff723a]' : 'text-black'}`}
                                to="/universities"
                            >
                                {t("Universities")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={`font-bold capitalize transition duration-500 ${location.pathname === '/portfolio' ? 'text-[#ff723a] border-b-2 border-[#ff723a]' : 'text-black'}`}
                                to="/portfolio"
                            >
                                {t("portfolio")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={`font-bold capitalize transition duration-500 ${location.pathname === '/courses' ? 'text-[#ff723a] border-b-2 border-[#ff723a]' : 'text-black'}`}
                                to="/courses"
                            >
                                {t("Courses")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={`font-bold capitalize transition duration-500 ${location.pathname === '/contact' ? 'text-[#ff723a]' : 'text-black'}`}
                                to="/contact"
                            >
                                {t("Contact_us")}
                            </Link>
                        </li>
                    </ul>
                </nav>

                <div className="d-none flex items-center gap-4 ">
                    <div className="relative">
                        <button onClick={toggleDropdown} className="bg-[#ff723a] text-white py-2 px-10 rounded font-bold">
                            {t("Language")}
                            <span className={`transform transition-transform ${isOpen ? 'rotate-180' : ''}`}>▼</span>
                        </button>

                        {isOpen && (
                            <div className="w-full absolute top-full mt-2 bg-white shadow-md rounded w-[100px]">
                                <button onClick={() => changeLanguage('en')} className="block w-full text-left px-4 py-2 hover:bg-gray-100">
                                    English
                                </button>
                                <button onClick={() => changeLanguage('ar')} className="block w-full text-left px-4 py-2 hover:bg-gray-100">
                                    العربية
                                </button>
                            </div>
                        )}
                    </div>

                    <Link to="/consultations" className="bg-[#ff723a] text-white py-2 px-10 rounded font-bold">
                        {t("Consultations")}
                    </Link>
                </div>

                <button className={`menu ${isMenuActive ? 'active' : ''}`} onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>

            {/* Mobile Navigation */}
            {isMenuActive && (
                <nav className="absolute top-full left-0 right-0 bg-white p-4 shadow-md md:hidden">
                    <ul className="flex flex-col gap-4">
                        <li>
                            <Link to="/" className="block font-bold">{t("Home")}</Link>
                        </li>
                        <li>
                            <Link to="/about" className="block font-bold">{t("About_us")}</Link>
                        </li>
                        <li>
                            <Link to="/major" className="block font-bold">{t("University_Majors")}</Link>
                        </li>
                        <li>
                            <Link to="/universities" className="block font-bold">{t("Universities")}</Link>
                        </li>
                        <li>
                            <Link to="/portfolio" className="block font-bold">{t("portfolio")}</Link>
                        </li>
                        <li>
                            <Link to="/courses" className="block font-bold">{t("Courses")}</Link>
                        </li>
                        <li>
                            <Link to="/contact" className="block font-bold">{t("Contact_us")}</Link>
                        </li>
                    </ul>
                    <div className="flex items-center gap-4">
                        <div className="relative">
                            <button onClick={toggleDropdown} className="bg-[#ff723a] text-white py-2 px-10 rounded font-bold">
                                {t("Language")}
                                <span className={`transform transition-transform ${isOpen ? 'rotate-180' : ''}`}>▼</span>
                            </button>

                            {isOpen && (
                                <div className="w-full absolute top-full mt-2 bg-white shadow-md rounded w-[100px]">
                                    <button onClick={() => changeLanguage('en')} className="block w-full text-left px-4 py-2 hover:bg-gray-100">
                                        English
                                    </button>
                                    <button onClick={() => changeLanguage('ar')} className="block w-full text-left px-4 py-2 hover:bg-gray-100">
                                        العربية
                                    </button>
                                </div>
                            )}
                        </div>

                        <Link to="/consultations" className="bg-[#ff723a] text-white py-2 px-10 rounded font-bold">
                            {t("Consultations")}
                        </Link>
                    </div>
                </nav>

            )}
        </header>
    );
}
