import React, { useEffect } from 'react';

const GoTop = () => {
    useEffect(() => {
        const goTop = document.querySelector('.goTop');
        const footer = document.querySelector('footer');
        const homeMedia = document.querySelector('.homeMedia');

        const handleScroll = () => {
            if (window.scrollY >= 400) {
                goTop.style.display = 'block';
            } else {
                goTop.style.display = 'none';
            }

            if (footer && window.scrollY >= footer.offsetTop - 600) {
                if (homeMedia) {
                    goTop.style.display = 'none';
                    homeMedia.style.display = 'none';
                }
            } else {
                if (homeMedia) {
                    goTop.style.display = 'flex';
                    homeMedia.style.display = 'flex';
                }
            }
        };

        const handleClick = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };

        goTop.addEventListener('click', handleClick);
        window.addEventListener('scroll', handleScroll);

        return () => {
            goTop.removeEventListener('click', handleClick);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="goTop">
            <span>
                <img className='w-[30px]' src={require("../Assest/icons/arrow-top.svg").default} alt='' />
            </span>
        </div>
    );
};

export default GoTop;
