import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import useScrollAnimation from '../useScrollAnimation';
export default function About() {
    const { t } = useTranslation();
    useScrollAnimation();
    return (
        <>
            <section className="landing">
                <div className="container h-[100%] flex items-center">
                    <div className="flex flex-col gap-[10px]">
                        <div className="btmAni2000 text-[50px] font-bold capitalize text-[#FF723A]">{t("About_us")}</div>
                        <div className="btmAni25002 home-pages-title flex items-center gap-[5px] text-[20px] capitalize">
                            {t("Home")} - <div className="text-[#FF723A]">{t("About_us")}</div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container py-[40px] relative'>
                    <div className='flex flex-col gap-[15px]'>
                        <div className='btmAni20002 text-[35px] font-bold capitalize'>{t("About Us - SmartITSM")}</div>
                        <p className="btmAni3000">
                            {t("At SmartITSM")}
                        </p>
                    </div>
                </div>
            </section>

            <section>
                <div className='container py-[40px] relative flex flex-col gap-[15px]'>
                    <div className='btmAni3000 text-[35px] font-bold capitalize'>{t("Our Services")}</div>
                    <div className='f-col g-20 flex flex-wrap gap-5 justify-center'>
                        <div className='respon-100 flex js-scroll fade-btm flex-col gap-2.5 items-center justify-center w-1/5 bg-white py-7 text-[#ff723a] border-2 border-[#ff723a] rounded-[9px] text-center shadow-[0px_0px_10px_#ff723a] font-bold'>
                            <img className='w-[50px] h-[50px]' src={require("../../Assest/icons/social.svg").default} alt='' />
                            <div>{t("Social Media and Page Management")}</div>
                        </div>
                        <div className='respon-100 flex js-scroll fade-btm flex-col gap-2.5 items-center justify-center w-1/5 bg-white py-7 text-[#ff723a] border-2 border-[#ff723a] rounded-[9px] text-center shadow-[0px_0px_10px_#ff723a] font-bold'>
                            <img className='w-[50px] h-[50px]' src={require("../../Assest/icons/website.svg").default} alt='' />
                            <div>{t("Creating Websites")}</div>
                        </div>
                        <div className='respon-100 flex js-scroll fade-btm flex-col gap-2.5 items-center justify-center w-1/5 bg-white py-7 text-[#ff723a] border-2 border-[#ff723a] rounded-[9px] text-center shadow-[0px_0px_10px_#ff723a] font-bold'>
                            <img className='w-[50px] h-[50px]' src={require("../../Assest/icons/ux.svg").default} alt='' />
                            <div>{t("UI / UX Design")}</div>
                        </div>
                        <div className='respon-100 flex js-scroll fade-btm flex-col gap-2.5 items-center justify-center w-1/5 bg-white py-7 text-[#ff723a] border-2 border-[#ff723a] rounded-[9px] text-center shadow-[0px_0px_10px_#ff723a] font-bold'>
                            <img className='w-[50px] h-[50px]' src={require("../../Assest/icons/nfc.svg").default} alt='' />
                            <div>{t("NFC Cards")}</div>
                        </div>
                        <div className='respon-100 flex js-scroll fade-btm flex-col gap-2.5 items-center justify-center w-1/5 bg-white py-7 text-[#ff723a] border-2 border-[#ff723a] rounded-[9px] text-center shadow-[0px_0px_10px_#ff723a] font-bold'>
                            <img className='w-[50px] h-[50px]' src={require("../../Assest/icons/employee.svg").default} alt='' />
                            <div>{t("Employee Cards")}</div>
                        </div>
                        <div className='respon-100 flex js-scroll fade-btm flex-col gap-2.5 items-center justify-center w-1/5 bg-white py-7 text-[#ff723a] border-2 border-[#ff723a] rounded-[9px] text-center shadow-[0px_0px_10px_#ff723a] font-bold'>
                            <img className='w-[50px] h-[50px]' src={require("../../Assest/icons/office.svg").default} alt='' />
                            <div>{t("Office Stands with Names or Advertisements (Acrylic)")}</div>
                        </div>
                        <div className='respon-100 flex js-scroll fade-btm flex-col gap-2.5 items-center justify-center w-1/5 bg-white py-7 text-[#ff723a] border-2 border-[#ff723a] rounded-[9px] text-center shadow-[0px_0px_10px_#ff723a] font-bold'>
                            <img className='w-[50px] h-[50px]' src={require("../../Assest/icons/Printed.svg").default} alt='' />
                            <div>{t("Printed")}</div>
                        </div>
                        <div className='respon-100 flex js-scroll fade-btm flex-col gap-2.5 items-center justify-center w-1/5 bg-white py-7 text-[#ff723a] border-2 border-[#ff723a] rounded-[9px] text-center shadow-[0px_0px_10px_#ff723a] font-bold'>
                            <img className='w-[50px] h-[50px]' src={require("../../Assest/icons/card.svg").default} alt='' />
                            <div>{t("All Types of Paper Cards")}</div>
                        </div>
                        <div className='respon-100 flex js-scroll fade-btm flex-col gap-2.5 items-center justify-center w-1/5 bg-white py-7 text-[#ff723a] border-2 border-[#ff723a] rounded-[9px] text-center shadow-[0px_0px_10px_#ff723a] font-bold'>
                            <img className='w-[50px] h-[50px]' src={require("../../Assest/icons/google.svg").default} alt='' />
                            <div>{t("Creating a Google Business Account and Search Engine Optimization")}</div>
                        </div>
                        <div className='respon-100 flex js-scroll fade-btm flex-col gap-2.5 items-center justify-center w-1/5 bg-white py-7 text-[#ff723a] border-2 border-[#ff723a] rounded-[9px] text-center shadow-[0px_0px_10px_#ff723a] font-bold'>
                            <img className='w-[50px] h-[50px]' src={require("../../Assest/icons/qr.svg").default} alt='' />
                            <div>{t("Creating a Custom QR Code")}</div>
                        </div>
                        <div className='respon-100 flex js-scroll fade-btm flex-col gap-2.5 items-center justify-center w-1/5 bg-white py-7 text-[#ff723a] border-2 border-[#ff723a] rounded-[9px] text-center shadow-[0px_0px_10px_#ff723a] font-bold'>
                            <img className='w-[50px] h-[50px]' src={require("../../Assest/icons/Identity.svg").default} alt='' />
                            <div>{t("Creating a Complete Visual Identity")}</div>
                        </div>
                    </div>
                    <div className='flex flex-col items-center gap-[10px]'>
                        <div className="js-scroll fade-top text-[20px] font-bold capitalize text-center">{t("You can see our projects by going to the portfolio page")}</div>
                        <Link className="js-scroll fade-btm bg-[#ff723a] text-white py-2 px-10 rounded font-bold capitalize" to={"/portfolio"}>{t("portfolio page")}</Link>
                    </div>
                </div>
            </section>
        </>
    );
}