import { useEffect } from 'react';


const useScrollAnimation = () => {
    useEffect(() => {
        const scrollElements = document.querySelectorAll(".js-scroll");

        const handleIntersection = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("scrolled");
                } else {
                    entry.target.classList.remove("scrolled");
                }
            });
        };

        const observerOptions = {
            root: null, // Observe based on the viewport
            rootMargin: "0px",
            threshold: 0.25 // Trigger when 25% of the element is in view
        };

        const observer = new IntersectionObserver(handleIntersection, observerOptions);

        scrollElements.forEach(element => {
            observer.observe(element);
        });

        return () => {
            // Clean up observer on unmount
            scrollElements.forEach(element => {
                observer.unobserve(element);
            });
        };
    }, []);
};

export default useScrollAnimation;
