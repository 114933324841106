// universityMajors.js
const universityMajors = [
    {
        id: 1,
        name: "College of Humanities",
        description: "Focuses on the study of humans and society, including aspects of mind, behavior, culture, and thought.",
        image: require('../../../src/Assest/major/Humanities.png'),
        commonMajors: [
            {
                name: "Psychology",
                description: "Study of human behavior and thought, with applications in mental health and personal development."
            },
            {
                name: "Sociology",
                description: "Study of social relationships, institutions, and human communities."
            },
            {
                name: "History",
                description: "Analysis of historical events to understand human development and interpret the past."
            },
            {
                name: "Literature",
                description: "Exploration of world literature and understanding culture through literary texts."
            },
            {
                name: "Philosophy",
                description: "Study of fundamental questions about life, knowledge, and existence."
            }
        ],
    },
    {
        id: 2,
        name: "College of Natural Sciences",
        description: "Focuses on the study of natural laws governing the universe, from the smallest particles to planets and stars.",
        image: require('../../../src/Assest/major/Natural Sciences.jpg'),
        commonMajors: [
            {
                name: "Physics",
                description: "Study of matter, energy, and forces, focusing on scientific and technological applications."
            },
            {
                name: "Chemistry",
                description: "Study of the composition of materials and their reactions, including applications in medicine and industry."
            },
            {
                name: "Biology",
                description: "Study of living organisms, from cells and systems to environments and ecosystems."
            },
            {
                name: "Geology",
                description: "Study of the Earth and its layers, including seismology, minerals, and geomorphology."
            }
        ],
    },
    {
        id: 3,
        name: "College of Engineering",
        description: "Dedicated to developing technical solutions to improve life by designing and building structures, machines, and systems.",
        image: require('../../../src/Assest/major/Engineering.jpg'),
        commonMajors: [
            {
                name: "Civil Engineering",
                description: "Designing and constructing infrastructure such as buildings, bridges, and dams."
            },
            {
                name: "Mechanical Engineering",
                description: "Designing and manufacturing complex mechanical systems and machines."
            },
            {
                name: "Electrical Engineering",
                description: "Study and development of electrical and electronic systems, including communications and power."
            },
            {
                name: "Industrial Engineering",
                description: "Improving productivity efficiency and reducing costs in manufacturing and service processes."
            },
            {
                name: "Computer Engineering",
                description: "Designing and developing hardware and software, focusing on computing applications."
            }
        ],
    },
    {
        id: 4,
        name: "College of Medicine and Health Sciences",
        description: "Provides specialized education and training in healthcare and medical sciences.",
        image: require('../../../src/Assest/major/Medicine and Health Sciences.jpg'),
        commonMajors: [
            {
                name: "Medicine",
                description: "Study of diagnosing and treating diseases, along with prevention and surgical intervention."
            },
            {
                name: "Dentistry",
                description: "Treatment and diagnosis of oral, dental, and gum diseases."
            },
            {
                name: "Pharmacy",
                description: "Study of medications and their mechanisms, focusing on drug development and dispensing."
            },
            {
                name: "Nursing",
                description: "Providing comprehensive nursing care to patients and working alongside medical staff."
            },
            {
                name: "Physical Therapy",
                description: "Providing treatment to individuals with physical injuries to improve movement and reduce pain."
            }
        ],
    },
    {
        id: 5,
        name: "College of Business Administration",
        description: "Prepares students to engage in the job market by teaching management and leadership skills.",
        image: require('../../../src/Assest/major/Business Administration.jpg'),
        commonMajors: [
            {
                name: "Business Administration",
                description: "Developing management and leadership skills and managing companies."
            },
            {
                name: "Accounting",
                description: "Recording and analyzing financial data, including auditing and tax accounting."
            },
            {
                name: "Marketing",
                description: "Strategies for promoting products and understanding consumer behavior and advertising planning."
            },
            {
                name: "Finance",
                description: "Managing money, including investments, financial planning, and personal finance."
            },
            {
                name: "Management Information Systems",
                description: "Integrating technology with management to improve business performance."
            }
        ],
    },
    {
        id: 6,
        name: "College of Information Technology",
        description: "Focuses on teaching students how to develop and protect software systems, as well as data analysis.",
        image: require('../../../src/Assest/major/Information Technology.jpg'),
        commonMajors: [
            {
                name: "Computer Science",
                description: "Programming, software development, and intelligent systems."
            },
            {
                name: "Software Engineering",
                description: "Designing and developing software applications with a focus on quality and efficiency."
            },
            {
                name: "Cybersecurity",
                description: "Protecting networks and information from cyber attacks."
            },
            {
                name: "Artificial Intelligence",
                description: "Developing intelligent systems capable of performing tasks that mimic human intelligence."
            },
            {
                name: "Data Analysis",
                description: "Extracting and analyzing data for informed decision-making."
            }
        ],
    },
    {
        id: 7,
        name: "College of Arts and Design",
        description: "Teaches students how to express ideas visually through fine arts and creative design.",
        image: require('../../../src/Assest/major/Arts and Design.jpg'),
        commonMajors: [
            {
                name: "Fine Arts",
                description: "Painting, sculpture, and artistic photography, focusing on creative expression."
            },
            {
                name: "Graphic Design",
                description: "Designing visual elements used in media, advertising, and branding."
            },
            {
                name: "Interior Design",
                description: "Planning and coordinating interior spaces to create functional and appealing environments."
            },
            {
                name: "Interior Architecture",
                description: "Designing architectural interior spaces in innovative ways."
            },
            {
                name: "Film and Television",
                description: "Studying and developing cinematic works and television production."
            }
        ],
    },
    {
        id: 8,
        name: "College of Agricultural and Environmental Sciences",
        description: "Focuses on the study of agriculture and the environment, developing sustainable farming techniques.",
        image: require('../../../src/Assest/major/Agricultural and Environmental Sciences.jpg'),
        commonMajors: [
            {
                name: "Agricultural Engineering",
                description: "Designing agricultural systems and improving food production efficiency."
            },
            {
                name: "Agricultural Biotechnology",
                description: "Using technology to improve agriculture and crops."
            },
            {
                name: "Food Science",
                description: "Studying and developing food processing techniques and ensuring quality and safety."
            },
            {
                name: "Environmental Science and Earth Sciences",
                description: "Conserving natural resources and protecting the environment from pollution."
            }
        ],
    },
    {
        id: 9,
        name: "College of Languages",
        description: "Teaches students how to communicate effectively in different languages, along with translation and cultural studies.",
        image: require('../../../src/Assest/major/Languages.png'),
        commonMajors: [
            {
                name: "English Language and Literature",
                description: "Enhancing reading and writing skills in English, with the study of English literature."
            },
            {
                name: "French Language",
                description: "Learning French for use in translation and international communication."
            },
            {
                name: "Translation",
                description: "Training students in simultaneous and written translation between languages."
            }
        ],
    },
    {
        id: 10,
        name: "College of Law",
        description: "Focuses on studying laws and legal systems, preparing students for a legal career or work in the legal field.",
        image: require('../../../src/Assest/major/Law.jpg'),
        commonMajors: [
            {
                name: "Public Law",
                description: "Studying legal systems regulating the relationship between the state and individuals, such as administrative and constitutional law."
            },
            {
                name: "Private Law",
                description: "Focuses on relations between individuals such as contracts and civil law."
            },
            {
                name: "International Law",
                description: "Studying laws governing relationships between countries and international organizations."
            },
            {
                name: "Arbitration and Commercial Law",
                description: "Resolving commercial disputes and the laws governing commercial activities."
            }
        ],
    },
    {
        id: 11,
        name: "College of Education",
        description: "Aims to prepare teachers and educators to work in educational institutions, focusing on teaching and learning methods.",
        image: require('../../../src/Assest/major/Education.jpg'),
        commonMajors: [
            {
                name: "Early Childhood Education",
                description: "Preparing teachers for teaching young children in kindergartens and primary schools."
            },
            {
                name: "Elementary Education",
                description: "Training teachers to teach multiple subjects to children in elementary school."
            },
            {
                name: "Special Education",
                description: "Training on how to teach students with special needs and provide educational support."
            },
            {
                name: "Psychological and Educational Counseling",
                description: "Preparing individuals to provide psychological counseling and academic guidance to students."
            }
        ],
    },
    {
        id: 12,
        name: "College of Media",
        description: "Focuses on teaching students how to produce media content, understand communication theories, and the impact of media on society.",
        image: require('../../../src/Assest/major/Media.jpg'),
        commonMajors: [
            {
                name: "Journalism",
                description: "Preparing students to work as reporters and editors, focusing on news and journalistic writing."
            },
            {
                name: "Public Relations",
                description: "Training on how to manage communication between organizations and the public."
            },
            {
                name: "Digital Media",
                description: "Producing digital content, including video, audio, and graphic design."
            },
            {
                name: "Broadcasting",
                description: "Learning how to produce and broadcast programs on radio and television."
            }
        ],
    },
    {
        id: 13,
        name: "College of Sports Sciences",
        description: "Prepares students for careers in sports coaching, physical education, and related sciences.",
        image: require('../../../src/Assest/major/Sports Sciences.jpg'),
        commonMajors: [
            {
                name: "Physical Education",
                description: "Preparing teachers to teach physical education and sports activities in schools."
            },
            {
                name: "Sports Management",
                description: "Managing sports clubs and organizing sports events and activities."
            },
            {
                name: "Sports Science",
                description: "Studying physiological, biological, and psychological aspects of sports performance."
            },
            {
                name: "Sports Therapy",
                description: "Providing care and rehabilitation for individuals with sports injuries."
            }
        ],
    },
    {
        id: 14,
        name: "College of Marine Studies",
        description: "Focuses on studying marine fields and navigation, preparing students for the maritime sector.",
        image: require('../../../src/Assest/major/Marine Studies.jpg'),
        commonMajors: [
            {
                name: "Marine Engineering",
                description: "Designing, maintaining, and operating ships and marine equipment."
            },
            {
                name: "Marine Navigation",
                description: "Preparing students to work as officers on ships and manage maritime transportation operations."
            },
            {
                name: "Marine Environmental Sciences",
                description: "Studying marine ecosystems and how to conserve marine resources."
            },
            {
                name: "Marine Economics",
                description: "Analyzing and managing economies related to maritime activities such as shipping and fishing."
            }
        ],
    },
    {
        id: 15,
        name: "College of Tourism and Hospitality",
        description: "Focuses on preparing students for careers in the tourism and hospitality industry, teaching skills related to hotel management and trip organization.",
        image: require('../../../src/Assest/major/Tourism and Hospitality.jpg'),
        commonMajors: [
            {
                name: "Hotel Management",
                description: "Learning how to manage and operate hotels and resorts, including hospitality services."
            },
            {
                name: "Tour Guide",
                description: "Training tourist guides to provide cultural tours and introduce tourists to local attractions."
            },
            {
                name: "Event Planning",
                description: "Preparing students to organize conferences, events, and exhibitions."
            },
            {
                name: "Sustainable Tourism",
                description: "Studying how to develop the tourism sector while preserving the environment and local communities."
            }
        ],
    },
    {
        id: 16,
        name: "College of Jurisprudence and Islamic Studies",
        description: "Focuses on studying Islamic sciences, providing in-depth education in jurisprudence, belief, and law.",
        image: require('../../../src/Assest/major/Jurisprudence and Islamic Studies.jpg'),
        commonMajors: [
            {
                name: "Jurisprudence and Law",
                description: "Studying Islamic jurisprudence and laws related to worship and transactions."
            },
            {
                name: "Islamic Studies",
                description: "Exploring Islamic history, theology, and contemporary issues in Islamic thought."
            },
            {
                name: "Da'wah and Islamic Culture",
                description: "Studying how to spread Islamic teachings and promote awareness of Islamic culture."
            }
        ],
    },
    {
        id: 17,
        name: "College of Accounting and Finance",
        description: "Prepares students to work in financial analysis, accounting, and investment management.",
        image: require('../../../src/Assest/major/Accounting and Finance.jpg'),
        commonMajors: [
            {
                name: "Public Accounting",
                description: "Training for roles in auditing and public accounting firms."
            },
            {
                name: "Financial Management",
                description: "Managing financial resources for organizations and investment companies."
            },
            {
                name: "Cost Accounting",
                description: "Analyzing costs and expenses in production and services to enhance profit management."
            }
        ],
    }
];


export default universityMajors;
