import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import useScrollAnimation from '../useScrollAnimation';
export default function Courses() {
    const { t } = useTranslation();
    useScrollAnimation();
   
    const initialTime = 10 * 24 * 60 * 60 + 13 * 60 * 60 + 47 * 60 + 20;

    const [timeLeft, setTimeLeft] = useState(initialTime);

    useEffect(() => {
        
        const interval = setInterval(() => {
            setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0)); 
        }, 1000);

        
        return () => clearInterval(interval);
    }, []);

    
    const days = Math.floor(timeLeft / (24 * 60 * 60));
    const hours = Math.floor((timeLeft % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((timeLeft % (60 * 60)) / 60);
    const seconds = timeLeft % 60;
    return (
        <>
            <section
                className="relative h-screen w-full flex flex-col items-center justify-center text-center text-white bg-cover bg-center"
                style={{
                    backgroundImage: "url(https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80)"
                }}
            >
                <div className="absolute top-0 right-0 bottom-0 left-0 bg-gray-900 opacity-90"></div>

                <div className="z-10 text-sm">{t("WE ARE COMING SOON!")}</div>

                <div className="flex items-end justify-center z-10">
                    <div className="m-2 sm:m-5">
                        <span className="text-indigo-600 font-bold text-xl sm:text-5xl">{days}</span>
                        <p>{t("Days")}</p>
                    </div>
                    <div className="m-2 sm:m-5">
                        <span className="text-indigo-600 font-bold text-xl sm:text-5xl">{hours}</span>
                        <p>{t("Hours")}</p>
                    </div>
                    <div className="m-2 sm:m-5">
                        <span className="text-indigo-600 font-bold text-xl sm:text-5xl">{minutes}</span>
                        <p>{t("Minutes")}</p>
                    </div>
                    <div className="m-2 sm:m-5">
                        <span className="text-indigo-600 font-bold text-xl sm:text-5xl">{seconds}</span>
                        <p>{t("Seconds")}</p>
                    </div>
                </div>

                <div className="rounded-md shadow z-10 mt-5">
                    <Link
                        to={"/home"}
                        className="w-full px-8 py-3 border border-transparent text-base leading-6 font-light rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-md md:px-16"
                    >
                        <span>{t("Back To Home")}</span>
                    </Link>
                </div>
            </section>

        </>
    );
}