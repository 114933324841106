import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from './Component/main/Header';
import Footer from './Component/main/Footer';
import Home from './Component/pages/Home';
import About from './Component/pages/About';
import Contact from './Component/pages/Contact';
import Courses from './Component/pages/Courses';
import Universities from './Component/pages/Universities';
import Major from './Component/pages/Major';
import Portfolio from "./Component/pages/portfolio";
import Consultations from './Component/pages/Consultations';
import GoTop from './Component/GoTop';
import useScrollAnimation from './Component/useScrollAnimation'; // Import the custom hook
import { Routes, Route } from 'react-router-dom';

const App = () => {
    const { i18n } = useTranslation();

    React.useEffect(() => {
        const savedLanguage = localStorage.getItem('language') || 'en';
        i18n.changeLanguage(savedLanguage);
    }, [i18n]);

    i18n.on('languageChanged', (lng) => {
        document.documentElement.lang = lng;
        document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
        localStorage.setItem('language', lng);
    });

    useScrollAnimation(); 

    return (
        <div>
            <Header />

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/major" element={<Major />} />
                <Route path="/universities" element={<Universities />} />
                <Route path='/portfolio' element={<Portfolio/>} />
                <Route path="/courses" element={<Courses />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/consultations" element={<Consultations />} />
            </Routes>
            
            <Footer />
            <GoTop />
        </div>
    );
};

export default App;
