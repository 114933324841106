import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import useScrollAnimation from '../useScrollAnimation';
export default function Consultations() {
    const { t } = useTranslation();
    useScrollAnimation();
    return (
        <>
            <section className="landing">
                <div className="container h-[100%] flex items-center">
                    <div className="flex flex-col gap-[10px]">
                        <div className="btmAni2000 text-[50px] font-bold capitalize text-[#FF723A]">{t("Consultations")}</div>
                        <div className="btmAni25002 home-pages-title flex items-center gap-[5px] text-[20px] capitalize">
                            {t("Home")} - <div className="text-[#FF723A]">{t("Consultations")}</div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container py-[40px] relative flex flex-col gap-[5px]">
                    <div className="btmAni2000 text-[35px] font-bold capitalize">{t("ITSM")}</div>
                    <div className="btmAni25002 text-[24px]">{t("We help you love what you do every day")}</div>
                    <div className="btmAni20002">{t("Discover your true potential and make the right career decisions with ITSM Consulting Sessions")}</div>
                </div>
            </section>

            <section>
                <div className='container py-[40px] relative flex flex-col gap-[10px]'>
                    <div className='btmAni2000 font-bold text-[30px] capitalize text-center flex flex-col items-center '>
                        <span>{t("Request your consultation now")}</span>
                        <span>{t("In Whatsapp")}</span>
                    </div>
                    <div className='f-col flex items-cednter gap-[10px]'>
                        <Link to={"https://wa.link/fcwpin"} target="_blank" className='respon-100 btmAni3000 relative w-[500px] overflow-hidden'>
                            <div className="flex items-center gap-[10px] absolute left-[15px] top-[15px]">
                                <img className=" w-[30px]" 
                                src={require("../../Assest/icons/whatsapp-contact.svg").default} alt="" />
                                <div className="font-bold">{t("Go To Whatsapp")}</div>
                            </div>
                            <img src={require("../../Assest/images/man.jpg")} alt='' />
                        </Link>
                        <Link to={"https://wa.link/uykivg"} target="_blank" className='respon-100 btmAni3000 relative w-[500px] overflow-hidden'>
                            <div className="flex items-center gap-[10px] absolute left-[15px] top-[15px]">
                                <img className=" w-[30px]" 
                                src={require("../../Assest/icons/whatsapp-contact.svg").default} alt="" />
                                <div className="font-bold">{t("Go To Whatsapp")}</div>
                            </div>
                            <img src={require("../../Assest/images/girl.jpg")} alt='' />
                        </Link>
                    </div>
                    <div className="js-scroll fade-btm font-bold text-[30px] capitalize text-center">
                        {t("Do not hesitate to request your consultation now from an expert")}
                    </div>
                    <div className="f-col g-20 flex items-center justify-between py-[40px]">
                        <div className="js-scroll fade-btm w-[150px] h-[150px] bg-[#ff723a] rounded-full flex items-center justify-center text-white capitalize font-bold border-2 border-[#eee] shadow-[0px_0px_24px_#00000036] text-center p-[10px]">{t("how to study")}</div>
                        <div className="js-scroll fade-top w-[150px] h-[150px] bg-[#ff723a] rounded-full flex items-center justify-center text-white capitalize font-bold border-2 border-[#eee] shadow-[0px_0px_24px_#00000036] text-center p-[10px]">{t("the best times to study")}</div>
                        <div className="js-scroll fade-btm w-[150px] h-[150px] bg-[#ff723a] rounded-full flex items-center justify-center text-white capitalize font-bold border-2 border-[#eee] shadow-[0px_0px_24px_#00000036] text-center p-[10px]">{t("the best university major")}</div>
                        <div className="js-scroll fade-top w-[150px] h-[150px] bg-[#ff723a] rounded-full flex items-center justify-center text-white capitalize font-bold border-2 border-[#eee] shadow-[0px_0px_24px_#00000036] text-center p-[10px]">{t("the best university")}</div>
                        <div className="js-scroll fade-btm w-[150px] h-[150px] bg-[#ff723a] rounded-full flex items-center justify-center text-white capitalize font-bold border-2 border-[#eee] shadow-[0px_0px_24px_#00000036] text-center p-[10px]">{t("the best teachers in the Kingdom")}</div>
                        <div className="js-scroll fade-top w-[150px] h-[150px] bg-[#ff723a] rounded-full flex items-center justify-center text-white capitalize font-bold border-2 border-[#eee] shadow-[0px_0px_24px_#00000036] text-center p-[10px]">{t("how to get a high GPA")}</div>
                    </div>
                </div>
            </section>
        </>
    );
}