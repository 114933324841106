import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useScrollAnimation from '../useScrollAnimation';
export default function Home() {
    const { t } = useTranslation();
    useScrollAnimation();
    return (
        <>
            <section className="home relative overflow-hidden">
                <img className='d-none btmAni2000 image-landing-page absolute w-[550px] right-[0px] bottom-[-72px] ' src={require("../../Assest/images/01.png")} alt='' />
                <div className="container h-[100%] flex items-center">
                    <div className='respon-100 flex flex-col gap-4 items-start w-[50%]'>
                        <div className='btmAni2000 font-bold text-[35px] capitalize'>{t("Learn, invest in your future, and start your journey with us today!")}</div>
                        <p className='btmAni25002'>
                            {t("Discover a world")}
                        </p>
                        <Link to={"/consultations"} className='btmAni20002 button-landing bg-[#ff723a] text-white py-2 px-10 rounded font-bold capitalize'>{t("Get your consultation now")}</Link>
                    </div>
                </div>
            </section>

            <section className=''>
                <div className='main-title js-scroll fade-top'>{t("About_us")}</div>
                <div className='f-col container flex justify-between gap-[15px] items-center'>
                    <div className='respon-100 f-center text-center js-scroll fade-left flex flex-col gap-[10px] w-[50%] items-start'>
                        <div className='text-[25px] font-bold'>{t("About ITSM")}</div>
                        <p className=''>
                            {t("At SmartITSM")}
                        </p>
                        <Link to={"/about"} className='bg-[#ff723a] text-white py-2 px-10 rounded font-bold capitalize'>{t("Learn More")}</Link>
                    </div>
                    <div className='respon-100 f-center js-scroll fade-right w-[50%] flex justify-end items-center'>
                        <img className='w-[75%]' src={require("../../Assest/images/about.png")} alt='' />
                    </div>
                </div>
            </section>

            <section className='relative py-[40px]'>
                <div className='main-title js-scroll fade-top'>{t("our Services")}</div>
                <div className='container flex justify-around flex-col gap-[15px]'>
                    <div className='f-col g-20 flex justify-around'>
                        <div className='respon-100 flex js-scroll fade-btm flex-col gap-2.5 items-center justify-center w-1/5 bg-white py-7 text-[#ff723a] border-2 border-[#ff723a] rounded-[9px] text-center shadow-[0px_0px_10px_#ff723a] font-bold'>
                            <img className='w-[50px] h-[50px]' src={require("../../Assest/icons/social.svg").default} alt='' />
                            <div>{t("Social Media and Page Management")}</div>
                        </div>
                        <div className='respon-100 flex js-scroll fade-btm flex-col gap-2.5 items-center justify-center w-1/5 bg-white py-7 text-[#ff723a] border-2 border-[#ff723a] rounded-[9px] text-center shadow-[0px_0px_10px_#ff723a] font-bold'>
                            <img className='w-[50px] h-[50px]' src={require("../../Assest/icons/website.svg").default} alt='' />
                            <div>{t("Creating Websites")}</div>
                        </div>
                        <div className='respon-100 flex js-scroll fade-btm flex-col gap-2.5 items-center justify-center w-1/5 bg-white py-7 text-[#ff723a] border-2 border-[#ff723a] rounded-[9px] text-center shadow-[0px_0px_10px_#ff723a] font-bold'>
                            <img className='w-[50px] h-[50px]' src={require("../../Assest/icons/ux.svg").default} alt='' />
                            <div>{t("UI / UX Design")}</div>
                        </div>
                        <div className='respon-100 flex js-scroll fade-btm flex-col gap-2.5 items-center justify-center w-1/5 bg-white py-7 text-[#ff723a] border-2 border-[#ff723a] rounded-[9px] text-center shadow-[0px_0px_10px_#ff723a] font-bold'>
                            <img className='w-[50px] h-[50px]' src={require("../../Assest/icons/nfc.svg").default} alt='' />
                            <div>{t("NFC Cards")}</div>
                        </div>
                    </div>
                    <div className='flex justify-center items-center'>
                        <Link to={"/about"} className='js-scroll fade-btm bg-[#ff723a] text-white py-2 px-10 rounded font-bold capitalize'>{t("Learn More")}</Link>
                    </div>
                </div>
            </section>

            <section className='major relative overflow-hidden h-[50vh] py-[40px]'>
                <img className='d-none absolute left-[0px] bottom-[0px] w-[23%]' src={require("../../Assest/images/major.png")} alt='' />
                <div className='container h-full flex items-center justify-center'>
                    <div className='respon-100 flex flex-col gap-[15px] w-[50%] '>
                        <div className='js-scroll fade-top text-[28px] font-bold capitalize text-center'>
                            {t("Choose your university major with us carefully and get your consultation with us")}
                        </div>
                        <div className='flex justify-center items-center'>
                            <Link to={"/major"} className='js-scroll fade-btm bg-[#ff723a] text-white py-2 px-10 rounded font-bold capitalize'>{t("show More")}</Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className='flex flex-col gap-[15px] py-[40px]'>
                <div className='main-title js-scroll fade-top'>{t("Consultations")}</div>
                <section className='consultations relative py-[40px]'>
                    <div className='f-center text-center container h-full flex flex-col justify-end items-center'>
                        <div className='js-scroll fade-top text-[45px] font-bold capitalize'>
                            {t("Get your consultation with us now")}
                        </div>
                        <div className='js-scroll fade-top text-[35px] font-bold capitalize'>
                            {t("What are you waiting for")}
                        </div>
                        <div className='js-scroll fade-top text-[25px] font-bold capitalize'>
                            {t("We are here to help you")}
                        </div>
                    </div>
                </section>
                <div className='flex justify-center items-center'>
                    <Link to={"/consultations"} className='js-scroll fade-btm bg-[#ff723a] text-white py-2 px-10 rounded font-bold capitalize'>{t("Learn More")}</Link>
                </div>
            </section>
        </>
    );
}