import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import portfolioData from '../Data/portfolioData'; // استيراد البيانات
import { Link } from 'react-router-dom';
import useScrollAnimation from '../useScrollAnimation';

export default function Portfolio() {
    const { t } = useTranslation();

    // حالة لتخزين الفلتر النشط والبيانات المفلترة
    const [activeFilter, setActiveFilter] = useState('all');

    // دالة الفلترة لعرض العناصر بناءً على الفئة المختارة
    const filteredData = activeFilter === 'all'
        ? portfolioData
        : portfolioData.filter(item => item.category.toLowerCase() === activeFilter.toLowerCase());

    // التعامل مع الضغط على الفلتر
    const handleFilterClick = (category) => {
        setActiveFilter(category);
    };

    useScrollAnimation();

    return (
        <>
            <section className="landing">
                <div className="container h-[100%] flex items-center">
                    <div className="flex flex-col gap-[10px]">
                        <div className="btmAni2000 text-[50px] font-bold capitalize text-[#FF723A]">{t("portfolio")}</div>
                        <div className="btmAni25002 home-pages-title flex items-center gap-[5px] text-[20px] capitalize">
                            {t("Home")} - <div className="text-[#FF723A]">{t("portfolio")}</div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='relative py-[40px]'>
                <div className='container flex flex-col gap-[15px]'>

                    <div className='btmAni2000 filter flex items-center gap-[10px] flex-wrap uppercase'>
                        {['all', 'website', 'ui / ux', 'post', 'vedios', 'nfc cards', 'logos', 'qr code'].map(category => (
                            <div
                                key={category}
                                className={`shadow-lg p-2.5 px-12 rounded-md font-bold cursor-pointer transition-all duration-300 
                                ${activeFilter === category ? 'bg-[#ff723a] text-white' : 'bg-white text-[#333] hover:bg-[#ff723a] hover:text-white'}`}
                                onClick={() => handleFilterClick(category)}
                            >
                                {t(category)}
                            </div>
                        ))}
                    </div>

                    <Link to={""} className='d-f respon-100 f-col g-20 btmAni25002 filtered-data grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4 mt-4'>
                        {filteredData.map(item => (
                            <div
                                key={item.id}
                                className={`portfolio-item bg-white shadow-md rounded-lg overflow-hidden ${item.category === 'post' ? 'h-[550px]' : ''}`}
                            >
                                <img
                                    src={item.image}
                                    alt={item.name}
                                    className={`w-full ${item.category === 'post' ? 'h-[550px]' : 'h-[250px]'} object-cover`}
                                />
                            </div>
                        ))}
                    </Link>

                </div>
            </section>
        </>
    );
}
