const portfolioData = [
    { id: 1, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (1).png'), url: 'https://example.com/website1' },
    { id: 2, category: 'ui / ux', image: require('../../../src/Assest/soon.png'), url: 'https://example.com/uiux1' },
    { id: 3, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (1).png') },
    { id: 4, category: 'vedios', image: require('../../../src/Assest/soon.png'), url: 'https://example.com/video1' },
    { id: 5, category: 'nfc cards', image: require('../../../src/Assest/portfolio/nfc/1.png') },
    { id: 6, category: 'nfc cards', image: require('../../../src/Assest/portfolio/nfc/2.png') },
    { id: 7, category: 'nfc cards', image: require('../../../src/Assest/portfolio/nfc/3.png') },
    { id: 8, category: 'logos', image: require('../../../src/Assest/soon.png'), url: 'https://example.com/logo1' },
    { id: 9, category: 'qr code', image: require('../../../src/Assest/soon.png'), url: 'https://example.com/qrcode1' },
    { id: 10, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (9).png'), url: 'https://talalabujaafer.site/' },
    { id: 11, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (10).png'), url: 'https://micfert.com/' },
    { id: 12, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (11).png'), url: 'https://obadaoptics.com/' },
    { id: 13, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (12).png'), url: 'https://joa-optometrist.org/' },
    { id: 14, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (13).png'), url: 'https://prometeo.zaidelayan.site/' },
    { id: 15, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (14).png'), url: 'https://bonanzasoft.com/' },
    { id: 16, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (15).png'), url: 'https://production.pzk-group.com/' },
    { id: 17, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (16).png'), url: 'https://7beansjo.com/' },
    { id: 18, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (17).png'), url: 'https://soft.pzk-group.com/' },
    { id: 19, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (18).png'), url: 'https://albaseel.com/' },
    { id: 20, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (19).png'), url: 'https://dashboard-r.netlify.app/' },
    { id: 21, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (20).png'), url: 'https://friendly-banoffee-3e75c3.netlify.app/' },
    { id: 22, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (21).png'), url: 'https://nkp-products.com/' },
    { id: 23, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (22).png'), url: 'https://65f73b64fbe72cb7f552f2b5--polite-beijinho-44d920.netlify.app/' },
    { id: 24, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (23).png'), url: 'https://ayeddana.com/' },
    { id: 25, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (24).png'), url: 'https://lerosevilla.com/' },
    { id: 26, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (25).png'), url: 'https://naqahacare.com/' },
    { id: 27, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (26).png'), url: 'https://petra-ms.com/wellwomenhealth/' },
    { id: 28, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (27).png'), url: 'https://petra-ms.com/jo-explore/' },
    { id: 29, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (3).png'), url: 'https://example.com/website23' },
    { id: 30, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (5).png'), url: 'https://r.zaidelayan.site/' },
    { id: 31, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (6).png'), url: "https://7omei.petra-ms.com/" },
    { id: 32, category: 'website', image: require('../../../src/Assest/portfolio/website/1 (7).png'), url: 'https://360.petra-ms.com/' },
    { id: 33, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (10).png') },
    { id: 34, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (11).png') },
    { id: 35, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (12).png') },
    { id: 36, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (13).png') },
    { id: 37, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (14).png') },
    { id: 38, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (15).png') },
    { id: 39, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (16).png') },
    { id: 40, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (17).png') },
    { id: 41, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (18).png') },
    { id: 42, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (19).png') },
    { id: 43, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (2).png') },
    { id: 44, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (20).png') },
    { id: 45, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (21).png') },
    { id: 46, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (22).png') },
    { id: 47, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (23).png') },
    { id: 48, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (24).png') },
    { id: 49, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (25).png') },
    { id: 50, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (26).png') },
    { id: 51, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (3).png') },
    { id: 52, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (4).png') },
    { id: 53, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (5).png') },
    { id: 54, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (6).png') },
    { id: 55, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (7).png') },
    { id: 56, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (8).png') },
    { id: 57, category: 'post', image: require('../../../src/Assest/portfolio/posts/1 (9).png') },
];

export default portfolioData;
