import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import useScrollAnimation from '../useScrollAnimation';
import { Link } from "react-router-dom";

export default function Contact() {
    const { t } = useTranslation();
    useScrollAnimation();

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [response, setResponse] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post("/sendEmail.php", formData);
            setResponse(res.data);
        } catch (error) {
            console.error("Error sending email", error);
            setResponse("Error sending your message, please try again.");
        }
    };

    return (
        <>
            <section className="landing">
                <div className="container h-[100%] flex items-center">
                    <div className="flex flex-col gap-[10px]">
                        <div className="btmAni2000 text-[50px] font-bold capitalize text-[#FF723A]">
                            {t("Contact_us")}
                        </div>
                        <div className="btmAni25002 home-pages-title flex items-center gap-[5px] text-[20px] capitalize">
                            {t("Home")} - <div className="text-[#FF723A]">{t("Contact_us")}</div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container relative py-[40px]'>
                    <div className='f-col flex justify-between gap-[40px]'>
                        <div className='respon-100 w-[50%] flex flex-col gap-[50px]'>
                            <div className='btmAni2000 font-bold text-[20px] text-[#ff723a]'>{t("Get In Touch And Grow Your Business Today")}</div>
                            <div className='flex flex-col gap-[10px]'>
                                <Link className='btmAni2000 flex items-center gap-[10px]' href={"tel:0792019411"}>
                                    <img src={require("../../Assest/icons/phone-contact.svg").default} alt='' />
                                    <span>{t("phone")}</span>
                                </Link>
                                <a className='btmAni25002 flex items-center gap-[10px]' href={"mailto:info@smart-itsm.site"}>
                                    <img src={require("../../Assest/icons/email-contact.svg").default} alt='' />
                                    <span>{t("email")}</span>
                                </a>
                                <Link className='btmAni20002 flex items-center gap-[10px]' href={""}>
                                    <img src={require("../../Assest/icons/location-contact.svg").default} alt='' />
                                    <span>{t("location")}</span>
                                </Link>
                            </div>
                            <div className='w-[100%] rounded-[24px]'>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d216934.25563009776!2d35.78282257753034!3d31.83594582594906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151b5fb85d7981af%3A0x631c30c0f8dc65e8!2sAmman!5e0!3m2!1sen!2sjo!4v1729079119938!5m2!1sen!2sjo"
                                    width="600"
                                    height="450"
                                    style={{ border: 0 }}
                                    allowFullScreen
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                    className='btmAni3000 w-[100%] rounded-[24px]'
                                    title="Amman Location Map"
                                ></iframe>
                            </div>
                        </div>
                        <div className='respon-100 w-[50%] flex flex-col gap-[50px]'>
                            <div className='btmAni2000 font-bold text-[20px] text-[#ff723a]'>{t("form-title")}</div>
                            <form onSubmit={handleSubmit}>
                                <label className='btmAni20002 flex flex-col w-[100%] gap-1 font-bold'>
                                    {t("Name-form")}:
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                        className='p-1 text-[#ff723a] outline-none border border-[#ff723a] rounded'
                                    />
                                </label>
                                <br />
                                <label className='btmAni3000 flex flex-col w-[100%] gap-1 font-bold'>
                                    {t("Email-form")}:
                                    <input
                                        type="email"
                                        name="email"  // Changed from phone to email
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                        className='p-1 text-[#ff723a] outline-none border border-[#ff723a] rounded'
                                    />
                                </label>
                                <br />
                                <label className='btmAni2500 flex flex-col w-[100%] gap-1 font-bold'>
                                    {t("Message-form")}:
                                    <textarea
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                        className='p-1 text-[#ff723a] outline-none border h-[350px] border-[#ff723a] rounded max-h-[350px]'
                                    ></textarea>
                                </label>
                                <br />
                                <button className='btmAni30002 bg-[#ff723a] text-white py-2 px-10 rounded font-bold' type="submit">{t("Send")}</button>
                            </form>
                            {response && <p>{response}</p>}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
