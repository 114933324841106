export const universitiesData = {
    publicUniversities: [
      {
        name: "University of Jordan",
        url: "https://www.ju.edu.jo/",
        image: require("../../Assest/universitie/UJ.jpg"),
      },
      {
        name: "Yarmouk University",
        url: "https://www.yu.edu.jo/",
        image: require("../../Assest/universitie/Yarmouk.jpg"),
      },
      {
        name: "Mu'tah University",
        url: "https://www.mutah.edu.jo/",
        image: require("../../Assest/universitie/Mu'tah.jpg"),
      },
      {
        name: "Jordan University of Science and Technology",
        url: "https://www.just.edu.jo/",
        image: require("../../Assest/universitie/JUST.jpg"),
      },
      {
        name: "Al al-Bayt University",
        url: "https://www.aabu.edu.jo/",
        image: require("../../Assest/universitie/Al al-Bayt.jpg"),
      },
      {
        name: "Hashemite University",
        url: "https://www.hu.edu.jo/",
        image: require("../../Assest/universitie/Hashemite.jpg"),
      },
      {
        name: "Tafila Technical University",
        url: "https://www.ttu.edu.jo/",
        image: require("../../Assest/universitie/Tafila.jpg"),
      },
      {
        name: "Balqa Applied University",
        url: "https://www.bau.edu.jo/",
        image: require("../../Assest/universitie/Balqa.jpg"),
      },
      {
        name: "Al-Hussein Bin Talal University",
        url: "https://www.ahu.edu.jo/",
        image: require("../../Assest/universitie/Al-Hussein.jpg"),
      },
      {
        name: "World Islamic Sciences and Education University",
        url: "https://www.wiu.edu.jo/",
        image: require("../../Assest/universitie/World-Islamic.jpg"),
      },
    ],
    privateUniversities: [
      {
        name: "German Jordanian University",
        url: "https://www.gju.edu.jo/",
        image: require("../../Assest/universitie/German.jpg"),
      },
      {
        name: "Ahliya Amman University",
        url: "https://www.ammanu.edu.jo/",
        image: require("../../Assest/universitie/Ahliya.jpg"),
      },
      {
        name: "University of Science Applied Private University",
        url: "https://www.asu.edu.jo/",
        image: require("../../Assest/universitie/Science.jpg"),
      },
      {
        name: "Philadelphia University",
        url: "https://www.philadelphia.edu.jo/",
        image: require("../../Assest/universitie/Philadelphia.jpg"),
      },
      {
        name: "Zarqa University",
        url: "https://www.zu.edu.jo/",
        image: require("../../Assest/universitie/Zarqa.jpg"),
      },
      {
        name: "Irbid National University",
        url: "https://www.iu.edu.jo/",
        image: require("../../Assest/universitie/Irbid.jpg"),
      },
      {
        name: "Middle East University",
        url: "https://www.meu.edu.jo/",
        image: require("../../Assest/universitie/Middle-East.jpg"),
      },
      {
        name: "Petra University",
        url: "https://www.uop.edu.jo/",
        image: require("../../Assest/universitie/Petra.jpg"),
      },
      {
        name: "Applied University",
        url: "https://www.aum.edu.jo/",
        image: require("../../Assest/universitie/American.jpg"),
      },
    ],
  };
  