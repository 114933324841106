import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import universityMajors from '../Data/universityMajors';
import useScrollAnimation from '../useScrollAnimation';

export default function Major() {
    const { t } = useTranslation();
    const [selectedMajor, setSelectedMajor] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleMajorClick = (major) => {
        setSelectedMajor(major);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setSelectedMajor(null);
    };
    useScrollAnimation();

    return (
        <>
            <section className="landing">
                <div className="container h-full flex items-center">
                    <div className="flex flex-col gap-2">
                        <h1 className="btmAni2000 text-5xl font-bold text-[#FF723A] capitalize">
                            {t("Universities Major")}
                        </h1>
                        <div className="btmAni25002 home-pages-title flex items-center gap-1 text-xl capitalize">
                            {t("Home")} - <span className="text-[#FF723A]">{t("Universities Major")}</span>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative py-10">
                <div className="container flex flex-col gap-4">
                    <div className="btmAni20002 filtered-data grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
                        {universityMajors.map((major) => (
                            <div
                                key={major.id}
                                className="portfolio-item bg-white shadow-md rounded-lg overflow-hidden cursor-pointer"
                                onClick={() => handleMajorClick(major)}
                            >
                                <img src={major.image} alt={t(major.name)} className="w-full h-40 object-cover" />
                                <div className="p-4">
                                    <h3 className="font-bold text-lg">{t(major.name)}</h3>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {isPopupOpen && selectedMajor && (
                <div className="btmAni2000 popup fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="popup-content bg-white rounded-lg shadow-lg p-6 flex flex-col gap-5">
                        <div className='flex items-center justify-end'>
                            <span className="close cursor-pointer" onClick={closePopup}>
                                <img className='w-4' src={require("../../Assest/icons/close.svg").default} alt='Close' />
                            </span>
                        </div>
                        <div>
                            <h2 className='font-bold text-2xl'>{t(selectedMajor.name)}</h2>
                            <p>{t(selectedMajor.description)}</p>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <h3 className='font-bold text-xl'>{t("Common Majors")}</h3>
                            <ul className='f-col flex gap-2 flex-wrap'>
                                {selectedMajor.commonMajors.map((subItem, index) => (
                                    <li className='respon-100 bg-[#FF723A] text-white p-4 rounded-lg w-[32.333333%] flex flex-col items-center justify-center text-center gap-2.5' key={index}>
                                        <div className='font-bold'>
                                            {t(subItem.name)}
                                        </div>
                                        <div>
                                            {t(subItem.description)}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
